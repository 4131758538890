import React, { useState, useEffect } from "react"
import * as styles from "./ImageWithText.module.scss"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import getWindowDimensions from  "../../utilities/windowDimensions"

const ImageWithText = (
    {
      content,
      alignTop = false,
      imageFloat = false,
      headerContent = false,
      textAlignBot = false,
    }
  ) => {

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // console.log('content', content, windowDimensions)

  const image = getImage(content[0].node.frontmatter.image)
  const layout = content[0].node.frontmatter.layout
  const text = content[0].node.frontmatter.text
  const secondaryText = content[0].node.frontmatter.secondaryText
  const identifier = content[0].node.frontmatter.identifier
  const altText = content[0].node.frontmatter.altText
  const imageCaption = content[0].node.html ? content[0].node.html : content[0].node.frontmatter.imageCaption;
  const imageOnly = content[0].node.frontmatter.imageOnly
  const imageCenter = content[0].node.frontmatter.imageCenter


  let mobileImageSize
  let mobileImagePosition
  if (content[0].node.frontmatter.options) {
    mobileImagePosition = content[0].node.frontmatter.options[0].mobileImagePosition
    mobileImageSize = content[0].node.frontmatter.options[0].mobileImageSize
  }

  let offsetTop;
  let desktopBottomMargin;
  let offsetLeft;
  let offsetRight;
  if (content[0].node.frontmatter.floatOptions) {
    offsetTop = content[0].node.frontmatter.floatOptions[0].offsetTop
    desktopBottomMargin = content[0].node.frontmatter.floatOptions[0].desktopBottomMargin
    offsetLeft = content[0].node.frontmatter.floatOptions[0].offsetLeft
    offsetRight = content[0].node.frontmatter.floatOptions[0].offsetRight
  }

  let viewPort;
  if (!windowDimensions) {
    viewPort = 1200
  } else {
    viewPort = windowDimensions.width
  }

  let Container;
  if ((desktopBottomMargin && imageFloat)) {
    if (viewPort >= 1440) {
      Container = styled.div`
        margin-bottom: ${desktopBottomMargin}px;
      `
    } else if (viewPort >= 1200) {
      Container = styled.div`
        margin-bottom: ${desktopBottomMargin / 1.3}px;
      `
    } else if (viewPort >= 768) {
      Container = styled.div`
        margin-bottom: ${desktopBottomMargin / 1.4}px;
      `
    } else {
      Container = styled.div`
        margin-bottom: 32px;
      `
    }
  } else {
    Container = styled.div`
      position: static;
    `
  }

  let ImgWrapper;
  if (imageFloat) {
    if (viewPort >= 1200) {
      ImgWrapper = styled.div`
        position: absolute;
        top: ${offsetTop}px;
      `
    } else if (viewPort >= 768) {
      ImgWrapper = styled.div`
        position: absolute;
        top: ${offsetTop / 2.5}px;
      `
    } else {
      ImgWrapper = styled.div`
        position: static;
      `
    }
  } else {
    ImgWrapper = styled.div`
      position: static;
    `
  }


  return (
    <Container
      className={`${styles.imageWithText}
                  ${layout === 'imageOnLeft' ? styles.imageOnLeft : styles.imageOnRight}
                  ${alignTop && styles.imageOffset}
                  ${imageCenter && styles.center}
                `}
      data-component={identifier}
    >
        <div
          className={styles.imgWrapper}
          data-mobile={mobileImageSize}
          data-mobile-position={mobileImagePosition}
          style={
            (viewPort >= 1200) ?
                {
                  marginLeft: `${offsetLeft}px`,
                  marginRight: `${offsetRight}px`,
                }
              :
                {}
          }
        >
          <ImgWrapper
          className={styles.imgWrapperInner}
          >
            <GatsbyImage
              image={image}
              alt={altText}
            />
            {
              imageCaption && <div className={styles.imgWrapperCaption} dangerouslySetInnerHTML={{__html:imageCaption}}/>
            }
          </ImgWrapper>
        </div>

      {
        !imageOnly &&
        <div className={`${styles.textWrapper}
                      `}>
          {headerContent
            ?
              <div className={styles.headerText}>
                <h2>{text}</h2>
                {secondaryText && <p className={styles.secondaryText}>{secondaryText}</p>}
              </div>
            :
              <p>{text}</p>
          }
        </div>
      }
    </Container>
  )
}

export default ImageWithText
