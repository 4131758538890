// extracted by mini-css-extract-plugin
export var imageWithText = "ImageWithText-module--imageWithText--3D8Z0";
export var imgWrapper = "ImageWithText-module--imgWrapper--1iItl";
export var imgWrapperCaption = "ImageWithText-module--imgWrapperCaption--3FBFz";
export var textWrapper = "ImageWithText-module--textWrapper--PYfhy";
export var imageCaption = "ImageWithText-module--imageCaption--tvTf3";
export var imgWrapperInner = "ImageWithText-module--imgWrapperInner--sGJHd";
export var headerText = "ImageWithText-module--headerText--2Rh4B";
export var secondaryText = "ImageWithText-module--secondaryText--2AgeS";
export var imageOnLeft = "ImageWithText-module--imageOnLeft--3AiCq";
export var imageOnRight = "ImageWithText-module--imageOnRight--VPs7k";
export var imageOffset = "ImageWithText-module--imageOffset--UxP9x";
export var textAlignBot = "ImageWithText-module--textAlignBot--32rmT";
export var center = "ImageWithText-module--center--1cb0I";