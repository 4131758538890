import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/SEO"
import Hero from "../components/Hero/Hero"
import BGImg from "../components/LargeBGImg/LargeBGImg"
import ImageWithText from "../components/ImageWithText/ImageWithText"
import TwoColumn from "../components/TwoColumn/TwoColumn"
import ImageWithHeading from "../components/ImageWithHeading/ImageWithHeading"

const Science = ({ data }) => {
  // Grab variable from .md file
  // Hero Sections
  const hero = data.hero.edges[0].node.frontmatter.heroImage.childImageSharp.gatsbyImageData;
  const heroAlt = data.hero.edges[0].node.frontmatter.heroImageAlt;
  const identifier = data.hero.edges[0].node.frontmatter.identifier;
  const heroDescription = data.hero.edges[0].node.frontmatter.description;

  const positions = {
    rowOneWrapper: {
      classes: "row-wrapper one",
      TextRow1: {
        classes: "mb-200",
          text1: {
            value: "Always",
            classes: "sixty"
          },
          text2: {
            value: "",
            classes: "empty"
          }
      },
      TextRow2: {
        classes: "",
          text1: {
            value: "Look",
            classes: "twenty"
          },
          text2: {
            value: "To",
            classes: "thirty Leftauto"
          }
      },
    },
    rowTwoWrapper: {
      classes: "row-wrapper two",
      TextRow1: {
        classes: "empty-height",
          text1: {
            value: "",
            classes: "empty"
          },
          text2: {
            value: " ",
            classes: "empty"
          }
      },
      TextRow2: {
        classes: "empty",
          text1: {
            value: "The",
            classes: "twenty Rightauto"
          },
          text2: {
            value: "",
            classes: "empty"
          }
      },
      TextRow3: {
        classes: "",
          text1: {
            value: "",
            classes: "empty"
          },
          text2: {
            value: "Future",
            classes: "thirty Leftauto text-right"
          }
      },
    }
  }

  //bg
  const introContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Story')
  const medicalContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'MEDICAL RESEARCH')
  const broadContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'BROAD INSTITUTE')
  const caltechContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'caltech')
  const stemcellContent = data.largeBGImg.edges.filter(item => item.node.frontmatter.title === 'Stem cell Research')


  //2 col
  const twoColumnMedical = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'medical')
  const twoColumnProgram = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'program')
  const expCol = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'experiment')
  const expTxtCol = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'experimenttext')
  const caltechCol = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'caltech')
  const caltechBroadCol = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'broad')
  const stemcellCol = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'stemcell')
  const fundingCol = data.twoColumn.edges.filter(item => item.node.frontmatter.section === 'funding')

  //img w/ text
  const equipmentComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'equipment')
  const notesComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'notes')
  const ogdenComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'ogden')
  const meetingsComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'meetings')
  const vipComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'vip')
  const caltechBroadComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'broad')
  const uscComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'usc')
  const labComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'lab')
  const cirmComponent = data.imageWithText.edges.filter(item => item.node.frontmatter.identifier === 'cirm')

  //img w/ header
  const ibdImgHead = data.imageWithHeading.edges.filter(item => item.node.frontmatter.identifier === 'ibd')
  const pressImgHead = data.imageWithHeading.edges.filter(item => item.node.frontmatter.identifier === 'press')
  const foundingImgHead = data.imageWithHeading.edges.filter(item => item.node.frontmatter.identifier === 'founding')
  const caltechImgHead = data.imageWithHeading.edges.filter(item => item.node.frontmatter.identifier === 'caltech')

  return (
    <>
      <Seo title={"Eli Broad | Science"} description={"Eli Broad was chronically attracted to solving problems, and he found his kindred spirit in scientists, the ultimate problem solvers."} />
      <Hero heroimage={hero} heroimagealt={heroAlt} identifier={identifier} positions={positions} description={heroDescription} />

      <BGImg content={introContent} />
      <BGImg content={medicalContent} />

      <TwoColumn content={twoColumnMedical}/>

      <ImageWithText content={equipmentComponent} />

      <ImageWithText content={notesComponent} />

      <ImageWithText content={ogdenComponent} />

      <TwoColumn content={twoColumnProgram}/>

      <ImageWithText content={meetingsComponent} />

      <ImageWithHeading content={ibdImgHead} />

      <BGImg content={broadContent} />

      <TwoColumn content={expCol} type="top" />

      <TwoColumn content={expTxtCol} type="bottom" />

      <ImageWithHeading content={pressImgHead} />

      <ImageWithText content={vipComponent} />

      <ImageWithHeading content={foundingImgHead} />

      <BGImg content={caltechContent} />

      <TwoColumn content={caltechCol}/>

      <ImageWithText content={caltechBroadComponent} />

      <TwoColumn content={caltechBroadCol}/>

      <ImageWithHeading content={caltechImgHead} />

      <BGImg content={stemcellContent} />

      <TwoColumn content={stemcellCol}/>

      <ImageWithText content={uscComponent} />

      <ImageWithText content={labComponent} />

      <TwoColumn content={fundingCol}/>

      <ImageWithText content={cirmComponent} />

      <div class="continue-link">
        <Link to="/art">Continue to Art</Link>
      </div>

    </>
  )
}

export default Science

export const query = graphql`
  {
    hero: allMarkdownRemark(filter: {frontmatter: {identifier: {eq: "science"}}}) {
      edges {
        node {
          frontmatter {
            heroImage {
              childImageSharp {
                gatsbyImageData(formats: AUTO, layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
            heroImageAlt
            identifier
            intro
            title
            description
          }
        }
      }
    }
    imageWithHeading: allMarkdownRemark(
      filter: {frontmatter: {component: {eq: "imageWithHeading"}, page: {eq: "science"}}}
    ) {
      edges {
        node {
          ...imageWithHeadingQuery
        }
      }
    }
    largeBGImg: allMarkdownRemark(filter: {frontmatter: {component: {eq: "LargeBGImg"}, page: {eq: "science"}}}) {
      edges {
        node {
          ...largeBGImgQuery
        }
      }
    }
    imageWithText: allMarkdownRemark(filter: {frontmatter: {component: {eq: "imageWithText"}, page: {eq: "science"}}}) {
      edges {
        node {
          ...imageWithTextQuery
        }
      }
    }
    twoColumn: allMarkdownRemark(filter: {frontmatter: {page: {eq: "science"}, component: {eq: "twocolumn"}}}) {
      edges {
        node {
          ...twoColumnQuery
        }
      }
    }
  }
`
