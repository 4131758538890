import React from "react"
import * as classes from "./LargeBGImg.module.scss"
import { getImage } from "gatsby-plugin-image"


const LargeBGImage = ({content}) => {
    const img = getImage(content[0].node.frontmatter.image.childImageSharp.gatsbyImageData)
    const title = content[0].node.frontmatter.title
    const titlePosition = content[0].node.frontmatter.typePosition;
    const imageCaption = content[0].node.html ? content[0].node.html : content[0].node.frontmatter.imageCaption;
    const imageCaptionPosition = content[0].node.frontmatter.imageCaptionPosition;
    const body = content[0].node.frontmatter.text;

    return (
        <div
        className={[classes.largeBgImage, `${imageCaptionPosition  ? imageCaptionPosition : ''}`, `${titlePosition  ? titlePosition : ''}`].join(' ')}
        style={{
            backgroundImage: "url(" + img.images.fallback.src + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
          caption-position={`${imageCaptionPosition  ? imageCaptionPosition : ''}`}
          title-position={`${titlePosition  ? titlePosition : ''}`}>

            {body !== ""
              ? <p>{body}</p>
              : <div className={classes.boldTitle}>{title}</div>
            }
            {imageCaption &&
              <span className={classes.caption} dangerouslySetInnerHTML={{__html:imageCaption}}/>
            }

        </div>
      )
  }


export default LargeBGImage
