import React from "react"
import * as classes from "./TwoColumn.module.scss"



const TwoColumn = ({
  content,
  type,
  reverseOnMobile = false,
  noPadding = false
}) => {

    const theColumns = content[0].node.frontmatter
    const theColumnsComponentName = content[0].node.frontmatter.section

        let LeftOffsetTop
        let RightOffsetTop
        let BottomPadding
        let TopPadding


        if (theColumns.options[0]) {
            LeftOffsetTop = theColumns.options[0].LeftOffsetTop
            RightOffsetTop = theColumns.options[0].RightOffsetTop
            BottomPadding = theColumns.options[0].BottomPadding
            TopPadding = theColumns.options[0].TopPadding
        }

    return (
      <div className={`
                    ${classes.twocolWrapper}
                    ${ reverseOnMobile ? classes.reverseOnMobile : ''}
                    ${ noPadding && "p-0"}
                    ${ BottomPadding && "bm-0" }
                    ${ TopPadding && "tm-0"  }
                    `
          }  data-component={theColumnsComponentName} data-type={type ? type : 'default'}>
        <div className={classes.row}>
          <div className={`
                          ${classes.column}
                          ${classes.columnOne}
                        `}>
            { theColumns.twocol[0] &&
              <div style={
                    {
                      marginTop: `${LeftOffsetTop}px`,
                    }
              }>
                {theColumns.twocol[0].subtitle && <h2 className={classes.columnHeading} dangerouslySetInnerHTML={{__html:theColumns.twocol[0].subtitle}}/>}
                {theColumns.twocol[0].text && <p className={classes.columnText} dangerouslySetInnerHTML={{__html:theColumns.twocol[0].text}}/>}
              </div>
            }
          </div>
          <div className={`
                          ${classes.column}
                          ${classes.columnTwo}
                        `}>
            { theColumns.twocol[1] &&
              <div style={
                {
                  marginTop: `${RightOffsetTop}px`,
                }
              }>
                {theColumns.twocol[1].subtitle && <h2 className={classes.columnHeading} dangerouslySetInnerHTML={{__html:theColumns.twocol[1].subtitle}}/>}
                {theColumns.twocol[1].text && <p className={classes.columnText} dangerouslySetInnerHTML={{__html:theColumns.twocol[1].text}}/>}
              </div>
            }
          </div>
        </div>
      </div>
    )
  }


export default TwoColumn
